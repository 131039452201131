/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Image, Subtitle, Text, PriceWrap } from '@swp/components'


export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Ceník"}>
        <Column className="mt--40 pb--80 pt--0" name={"xi6rhmztau"}>
          
          <ColumnWrap className="column__flex el--1 pb--0 pt--0" anim={null} style={{"maxWidth":1410}} columns={"1"}>
            
            <ColumnWrapper className="pb--0 pt--0" style={{"maxWidth":1150}}>
              
              <Title className="title-box pb--0 pl--0 pr--0 pt--0" style={{"maxWidth":""}} content={"Ceník"}>
              </Title>

              <Image className="--left pb--02 pl--02 pr--02 pt--02" alt={""} src={"https://cdn.swbpg.com/o/11591/f54a559b8f5e4d9086b55732bbd82975.svg"} svg={false} href={"/"} sizes={"100vw"} style={{"maxWidth":36}} srcSet={""} content={null}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--80 pt--10" anim={null} name={"xi6rhmztau"}>
          
          <ColumnWrap className="column__flex el--2 pb--0 pt--0 flex--stretch" anim={null} style={{"maxWidth":""}} columns={"2"}>
            
            <ColumnWrapper className="--border4 js-anim  --anim2 --anim-s4 mt--16 pb--50 pl--40 pr--40 pt--40" anim={"2"} animS={"4"} style={{"maxWidth":""}} border={"4"}>
              
              <Subtitle className="subtitle-box fs--43" style={{"maxWidth":451}} content={"Celodenní focení svatby / 12 hod."}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":468}} content={"Klikněte a začněte psát text, tento prostor je určený pro text. V případě potřeby jde u bloku textu změnit barvu, změnit velikost písma, řádkování, nebo zarovnat text."}>
              </Text>

              <Text className="text-box" style={{"maxWidth":468}} content={"<span style=\"font-weight: bold;\">Focení svatebčanů a obřadu</span>"}>
              </Text>

              <Text className="text-box mt--06" style={{"maxWidth":468}} content={"<span style=\"font-weight: bold;\">Cestovné po Praze a do 30 km v ceně</span>"}>
              </Text>

              <Text className="text-box mt--06" style={{"maxWidth":468}} content={"<span style=\"font-weight: bold;\">36 fotografií ve formátu 13 x 18 cm v albu</span>"}>
              </Text>

              <Subtitle className="subtitle-box" content={"179,— Kč"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper className="--border4 js-anim  --anim2 --anim-s4 mt--16 pb--50 pl--40 pr--40 pt--40" anim={"2"} animS={"4"} style={{"maxWidth":""}} border={"4"}>
              
              <Subtitle className="subtitle-box fs--43" style={{"maxWidth":451}} content={"Focení portrétu pro\n1 osobu / 3 hod."}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":468}} content={"Klikněte a začněte psát text, tento prostor je určený pro text. V případě potřeby jde u bloku textu změnit barvu, změnit velikost písma, řádkování, nebo zarovnat text."}>
              </Text>

              <Subtitle className="subtitle-box" content={"179,— Kč"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper className="--border4 js-anim  --anim2 --anim-s4 mt--16 pb--50 pl--40 pr--40 pt--40" anim={"2"} animS={"4"} style={{"anim":"2","animS":"4","maxWidth":""}} border={"4"}>
              
              <Subtitle className="subtitle-box fs--43" style={{"maxWidth":451}} content={"Focení na míru / 1 hod."}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":468}} content={"Klikněte a začněte psát text, tento prostor je určený pro text. V případě potřeby jde u bloku textu změnit barvu, změnit velikost písma, řádkování, nebo zarovnat text."}>
              </Text>

              <Subtitle className="subtitle-box" content={"179,— Kč"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper className="--border4 js-anim  --anim2 --anim-s4 mt--16 pb--50 pl--40 pr--40 pt--40" anim={"2"} animS={"4"} style={{"maxWidth":""}} border={"4"}>
              
              <Subtitle className="subtitle-box fs--43" style={{"maxWidth":451}} content={"Focení portrétu pro\n1 osobu / 3 hod."}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":468}} content={"Klikněte a začněte psát text, tento prostor je určený pro text. V případě potřeby jde u bloku textu změnit barvu, změnit velikost písma, řádkování, nebo zarovnat text."}>
              </Text>

              <Subtitle className="subtitle-box" content={"179,— Kč"}>
              </Subtitle>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--0 pt--0" name={"1zv03r3aiu9i"} style={{"backgroundColor":"var(--color-supplementary)"}} layout={"l30"}>
        </Column>


        <Column className="js-anim  --anim2 --anim-s4 pb--30 pt--30" anim={"2"} name={"76qrhkfotrr"} animS={"4"}>
          
          <ColumnWrap className="column__flex el--2" style={{"maxWidth":1410}} columns={"2"}>
            
            <ColumnWrapper >
              
              <Subtitle className="subtitle-box fs--43 pl--0 pr--0" content={"Další ..."}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":550}}>
              
              <PriceWrap className="mb--0 mt--0 pb--0 pl--0 pt--0">
                
                <ColumnWrapper >
                  
                  <Text className="text-box" content={"Pobyt v ateliéru (1 hod)"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"<span style=\"font-weight: bold;\">179,— Kč</span>"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap className="mb--0 mt--0 pb--0 pl--0 pt--0">
                
                <ColumnWrapper >
                  
                  <Text className="text-box" content={"Pobyt v ateliéru (1 hod)"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"<span style=\"font-weight: bold;\">179,— Kč</span>"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap className="mb--0 mt--0 pb--0 pl--0 pt--0">
                
                <ColumnWrapper >
                  
                  <Text className="text-box" content={"Svatební fotoalbum"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"<span style=\"font-weight: bold;\">179,— Kč</span>"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap className="mb--0 mt--0 pb--0 pl--0 pt--0">
                
                <ColumnWrapper >
                  
                  <Text className="text-box" content={"Doprava (1 km)"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"<span style=\"font-weight: bold;\">179,— Kč</span>"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap className="mb--0 mt--0 pb--0 pl--0 pt--0">
                
                <ColumnWrapper >
                  
                  <Text className="text-box" content={"Pobyt v ateliéru (1 hod)"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"<span style=\"font-weight: bold;\">179,— Kč</span><br>"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap className="mb--0 mt--0 pb--0 pl--0 pt--0">
                
                <ColumnWrapper >
                  
                  <Text className="text-box" content={"Svatební fotoalbum"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"<span style=\"font-weight: bold;\">179,— Kč</span><br>"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="mt--80 pb--80 pt--80" name={"kontakt"} style={{"backgroundColor":"var(--color-supplementary)"}}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s4 el--2 pb--30 pt--25 flex--top" anim={"2"} animS={"4"} style={{"maxWidth":1410}} columns={"2"}>
            
            <ColumnWrapper className="pb--0 pt--0" style={{"maxWidth":1150}}>
              
              <Title className="title-box pb--0 pl--0 pr--0 pt--0" style={{"maxWidth":""}} content={"<span style='color: var(--color-dominant)'>Ozvěte se mi ...</span>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper className="pb--0 pt--0" style={{"maxWidth":1150}}>
              
              <Text className="text-box" style={{"maxWidth":590}} content={"<span style='color: var(--color-dominant)'>Klikněte a začněte psát text, tento prostor je určený pro text. V případě potřeby jde u bloku textu změnit barvu, změnit velikost písma, změnit velikost řádkování, zarovnat text na stranu a dokonce nastavit maximální šířku textu v pixlech.</span>"}>
              </Text>

              <Text className="text-box" style={{"maxWidth":590}} content={"<span style='color: var(--color-dominant)'><a href=\"https://saywebpage.com\">Tvorba web stránek</a></span>"}>
              </Text>

              <Subtitle className="subtitle-box lh--16 mb--40" content={"<span style='color: var(--color-dominant)'>Petr Domanický<br>+420 797 811 2X0<br>info@vase-stranky.com</span>"}>
              </Subtitle>

              <Image className="--left pb--06 pl--06 pr--06 pt--06" alt={""} src={"https://cdn.swbpg.com/o/11591/cac5c902f16a47b7b7aea31fabd7fc79.svg"} svg={false} href={"mailto:[[UNIemail]]"} sizes={"100vw"} style={{"maxWidth":210}} srcSet={""} content={null}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}